/* eslint-disable react/prop-types */
import cn from '@helpers/utils'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'

type Props = {
  appearance:
    | 'link'
    | 'primary'
    | 'outline'
    | 'subtle-link'
    | 'outline-primary'
    | 'outline-danger'
    | 'text'
    | 'danger'
    | 'success'

  type: 'button' | 'submit' | 'reset'
  horizontalAlignment?: 'left' | 'center' | 'right' | 'justify' | 'start'
  spacing?: '0' | '1' | '2' | '3'
  children: ReactNode
  isLoading?: boolean
  block?: boolean
  justifyContent?: 'center' | 'start' | 'end'
  fullWidth?: boolean
  noHover?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      appearance = 'primary',
      type = 'submit',
      children,
      isLoading = false,
      block = false,
      justifyContent = 'center',
      fullWidth = false,
      className: classNameProp,
      ...props
    },
    ref
  ) => {
    'justify-center'
    'justify-start'
    'justify-end'

    let className = `py-[10px] flex items-center justify-${justifyContent} space-x-3 ${
      fullWidth ? 'w-full' : ''
    }`

    if (appearance === 'primary') {
      className = cn(
        className,
        !props.disabled && 'bg-primary-blue hover:bg-blue-800 text-white',
        'px-6 rounded-lg text-center font-medium focus:bg-blue-900'
      )
    } else if (appearance === 'link') {
      className = cn(
        className,
        'text-left text-primary-blue hover:text-blue-800 active:text-blue-900 underline'
      )
    } else if (appearance === 'outline') {
      className = cn(
        className,
        'px-8 rounded-lg text-center bg-white border border-border-gray hover:bg-border-gray'
      )
    } else if (appearance === 'subtle-link') {
      className = cn(className, 'text-primary-gray text-sm')
    } else if (appearance === 'outline-primary') {
      className = cn(
        className,
        !props.disabled &&
          'hover:bg-primary-blue hover:text-white bg-white text-primary-blue border border-primary-blue',
        'px-6 rounded-lg text-primary-blue text-center border border-primary-blue'
      )
    } else if (appearance === 'outline-danger') {
      className = cn(
        className,
        'px-6 rounded-lg text-primary-red text-center bg-white border border-primary-red hover:bg-primary-red hover:text-white focus:bg-primary-red focus:text-white'
      )
    } else if (appearance === 'danger') {
      className = cn(className, 'rounded-lg bg-primary-red text-white')
    } else if (appearance === 'text') {
      className = cn(
        className,
        'rounded-lg py-[10px] px-6',
        props.disabled
          ? `${props.noHover ? '' : 'hover:bg-border-gray '}hover:text-secondary-gray`
          : 'hover:bg-main-gray'
      )
    } else if (appearance === 'success') {
      className = cn(className, 'rounded-lg bg-hired-1 text-white')
    }

    if (block) {
      className = cn(className, 'w-full')
    }

    if (props.disabled) {
      className = cn(
        className,
        'bg-board-gray border-none text-secondary-gray disabled:cursor-not-allowed'
      )
    }

    if (classNameProp) {
      className = cn(className, classNameProp)
    }

    if (fullWidth) {
      className = cn(className, 'w-full')
    }

    return (
      <button
        {...props}
        ref={ref}
        aria-label="button"
        className={className}
        type={type === 'submit' ? 'submit' : 'button'}
        disabled={isLoading || props.disabled}
      >
        {isLoading ? (
          <div className="mx-auto h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent" />
        ) : (
          children
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
