import { Dialog, Transition } from '@headlessui/react'
import cn from '@helpers/utils'
import classNames from 'classnames'
import Image from 'next/image'
import React, { Fragment, ReactNode } from 'react'

import H2 from '@components/typography/heading-two'

type ModalProps = {
  open: boolean
  onClose: () => void
  children: ReactNode
  className?: string
  classNamePanel?: string
  title?: React.ReactNode
  isCloseButtonHidden?: boolean
  rootContentClassname?: string
  isHeaderHidden?: boolean
}

const Modal = ({
  open,
  onClose,
  children,
  className,
  classNamePanel,
  title,
  isCloseButtonHidden = false,
  isHeaderHidden = false,
  rootContentClassname,
}: ModalProps) => (
  <Transition appear show={open} as={Fragment}>
    <Dialog className="relative z-50" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0" />
      </Transition.Child>

      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <div className="flex min-h-full w-full items-center lg:w-auto">
            <Dialog.Panel
              className={cn(
                'mx-auto',
                'rounded-xl',
                'bg-white',
                'overflow-y-auto',
                'max-h-[40rem]',
                'lg:max-h-[calc(100vh-2rem)]',
                'm-8',
                'flex-1',
                'scrollbar-thin',
                'scrollbar-track-blue-100',
                'scrollbar-thumb-blue-500',
                'hover:scrollbar-thumb-blue-700',
                classNamePanel
              )}
            >
              {!isHeaderHidden && (
                <Dialog.Title
                  as="div"
                  className={classNames(
                    'sticky top-0 z-10 flex flex-row rounded-xl bg-white',
                    title ? 'justify-between' : 'justify-end',
                    !title && isCloseButtonHidden ? 'pt-6' : 'mb-4 p-4'
                  )}
                >
                  <H2>{title}</H2>
                  {!isCloseButtonHidden && (
                    <button type="button" onClick={onClose} className="h-5 w-5">
                      <Image
                        alt="ic_close"
                        src="/assets/icons/ic_close.svg"
                        width={24}
                        height={24}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </button>
                  )}
                </Dialog.Title>
              )}
              <Dialog.Description
                as="div"
                className={cn('px-6', 'pb-4', rootContentClassname, className)}
              >
                {children}
              </Dialog.Description>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
)

export default Modal
