const MODAL_NAME = {
  CROPPER_MODAL: 'CROPPER_MODAL',
  ONBOARD_MODAL: 'ONBOARD_MODAL',
  APPLICANT_MODAL: 'APPLICANT_MODAL',
  QUESTION_MODAL: 'QUESTION_MODAL',
  VIDEO_PLAYER_MODAL: 'VIDEO_PLAYER_MODAL',
  REPOST_MODAL: 'REPOST_MODAL',
  RECOMMENDED_CANDIDATE_FILTER: 'RECOMMENDED_CANDIDATE_FILTER',
  APPLICANT_FILTER_MODAL: 'APPLICANT_FILTER_MODAL',
  SHORTLIST_CONFIRMATION_MODAL: 'SHORTLIST_CONFIRMATION_MODAL',
  INVITE_CONFIRMATION_MODAL: 'INVITE_CONFIRMATION_MODAL',
  PASANG_LOKER_MODAL: 'PASANG_LOKER_MODAL',
  CHAT_FILTER_OPTIONS_MODAL: 'CHAT_FILTER_OPTIONS_MODAL',
  IMAGE_PREVIEW_MODAL: 'IMAGE_PREVIEW_MODAL',
  INTERVIEW_DETAIL_DRAWER: 'INTERVIEW_DETAIL_DRAWER',
  KYC_CONFIRMATION_MODAL: 'KYC_CONFIRMATION_MODAL',
  KYC_FORM_SENT_MODAL: 'KYC_FORM_SENT_MODAL',
  KYC_REVERIFY_REJECTED_MODAL: 'KYC_REVERIFY_REJECTED_MODAL',
  CANDIDATE_SHORTLIST_MODAL: 'CANDIDATE_SHORTLIST_MODAL',
  CANDIDATE_REJECT_MODAL: 'CANDIDATE_REJECT_MODAL',
  KYC_INREVIEW_NOTIFY_MODAL: 'KYC_INREVIEW_NOTIFY_MODAL',
  KYC_NOTICE_MODAL: 'KYC_NOTICE_MODAL',
  REPORT_CANDIDATE_MODAL: 'REPORT_CANDIDATE_MODAL',
  BLOCK_CANDIDATE_MODAL: 'BLOCK_CANDIDATE_MODAL',
  ASK_BLOCK_CANDIDATE_MODAL: 'ASK_BLOCK_CANDIDATE_MODAL',
  INTERVIEW_OPTION_MODAL: 'INTERVIEW_OPTION_MODAL',
  SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW',
  ASK_CREATE_INTERVIEW: 'ASK_CREATE_INTERVIEW',
  ASK_SEND_INTERVIEW: 'ASK_SEND_INTERVIEW',
  SHARE_INTERVIEW_RESULT: 'SHARE_INTERVIEW_RESULT',
  SHOW_INTERVIEW_RESULT: 'SHOW_INTERVIEW_RESULT',
  ASK_SEND_INTERVIEW_RESULT: 'ASK_SEND_INTERVIEW_RESULT',
  ASK_TO_HIRE_CANDIDATE: 'ASK_TO_HIRE_CANDIDATE',
  CREATE_INVITATION_TEMPLATE: 'CREATE_INVITATION_TEMPLATE',
  CANDIDATE_SEARCH_NOTICE_MODAL: 'CANDIDATE_SEARCH_NOTICE_MODAL',
  SWITCH_COMPANY_MODAL: 'SWITCH_COMPANY_MODAL',
  BOOST_FILTER_MODAL: 'BOOST_FILTER_MODAL',
  BOOST_NOTICE_MODAL: 'BOOST_NOTICE_MODAL',
  NOT_ENOUGH_COIN: 'NOT_ENOUGH_COIN',
  SHOW_PURCHASE_CONFIRMATION: 'SHOW_PURCHASE_CONFIRMATION',
  SHOW_PURCHASE_WA_CONFIRMATION: 'SHOW_PURCHASE_WA_CONFIRMATION',
  CHARGE_REPOST_JOB: 'CHARGE_REPOST_JOB',
  ADD_TO_POOL_MODAL: 'ADD_TO_POOL_MODAL',
  ASK_TO_DELETE_CANDIDATES: 'ASK_TO_DELETE_CANDIDATES',
  EDIT_TALENT_POOL: 'EDIT_TALENT_POOL',
  ASK_TO_DELETE_TALENT_POOL: 'ASK_TO_DELETE_TALENT_POOL',
  ASK_TO_UPGRADE: 'ASK_TO_UPGRADE',
} as const

export default MODAL_NAME
